<template>
  <section class="main">
    <div class="tool">
      <div class="row">
        <span class="label">课程id</span>
        <el-input v-model="subjectCourseId" placeholder="必填" class="input-text"></el-input>
      </div>
      <div class="row">
        <span class="label">fb paper id</span>
        <el-input v-model="fbPaperId" placeholder="比如：教学组织能力" class="input-text"></el-input>
      </div>
      <div class="row">
        <el-radio-group v-model="type" size="medium">
          <el-radio-button label="0">选择题</el-radio-button>
          <el-radio-button label="1">主观题</el-radio-button>
        </el-radio-group>
      </div>
      <div class="row">
        <el-button type="primary" @click="onSearch">查询</el-button>
        <el-button type="primary" @click="onExport">导出</el-button>
      </div>
    </div>
    <div class="table">
      <div style="text-align:left" v-if="waiting">
        <el-skeleton :rows="6" animated />
      </div>
      <el-table :data="tableData" highlight-current-row style="width: 100%" v-else>
        <el-table-column label="id" width="80">
          <template slot-scope="scope">
            <div v-html="scope.row.id"></div>
          </template>
        </el-table-column>
        <el-table-column label="题干">
          <template slot-scope="scope">
            <div v-html="scope.row.title"></div>
          </template>
        </el-table-column>
        <el-table-column label="选项">
          <template slot-scope="scope">
            <div v-html="scope.row.content"></div>
          </template>
        </el-table-column>
        <el-table-column label="材料">
          <template slot-scope="scope">
            <div v-html="scope.row.material"></div>
          </template>
        </el-table-column>
        <el-table-column label="来源" width="200">
          <template slot-scope="scope">
            {{ scope.row.source }}
          </template>
        </el-table-column>
        <el-table-column label="当前考点" width="200">
          <template slot-scope="scope">
            {{ scope.row.extraInfo }}
          </template>
        </el-table-column>
        <el-table-column label="fb的原始考点" width="200">
          <template slot-scope="scope">
            <div v-html="scope.row.fbKpInfo"></div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="150">
          <template slot-scope="scope">
            <el-button size="small" @click="onViewQuestion(scope.$index, scope.row)">预览</el-button>
            <el-button size="small" @click="onEditQuestion(scope.$index, scope.row)">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="footer">
      <el-pagination
        background
        layout="prev, pager, next"
        :current-page="currentPage"
        :page-count="totalPage"
        @current-change="onChangePage"
      ></el-pagination>
    </div>
  </section>
</template>

<script>
// 按卷子来修改题目考点
import { tikuApi } from "@/api/tikuApi";
import { mdUtil } from "@/util/mdUtil";
import msgLogger from "@/util/msgLogger";

export default {
  data() {
    return {
      baseUrl: "http://localhost:3000",
      currentPage: 1,
      totalPage: 1,
      ids: "",

      waiting: false,
      queryWords: [],

      subjectCourseId: 1,
      type: 0,
      fbPaperId: 0,
      start: 0,
      len: 15,
      md: "# hihihihi",
      tableData: [
        {
          title: "",
          content: "",
          extraInfo: "",
          fbKpInfo: "",
          source: "",
          material: "",
        },
      ],
    };
  },
  components: {},
  mounted() {
    let md = this.$markdownIt.render(" # hhh");
    this.md = md;
    const IS_PROD = ["production", "prod"].includes(process.env.NODE_ENV);
    if (IS_PROD) {
      this.baseUrl = "http://cms.kaiweitalk.cn";
    }

    console.log(process.env.VUE_APP_ENV);
  },
  methods: {
    onSearch() {
      this.waiting = true;
      this.queryWords = [];

      if (!this.subjectCourseId || this.subjectCourseId == 0) {
        msgLogger.warn("课程id必须有, 且大于0");
        return;
      }
      if (!this.fbPaperId) {
        this.fbPaperId = 0;
      }

      tikuApi
        .queryByFbPaperId(this.subjectCourseId, this.type, this.fbPaperId, this.start, this.len)
        .then((ret) => {
          console.log("..", ret);

          let { code, data, total } = ret;
          if (code == 0) {
            this.waiting = false;

            this.totalPage = parseInt(total / this.len) + (total % this.len == 0 ? 0 : 1);
            this.ids = data.map((x) => x.id).join(",");

            for (let item of data) {
              if (item.type == 0) {
                // choice
                item.title = mdUtil.richTextUnitToHtml(item.choiceQuestion.title);
                item.content = mdUtil.stringMdToHtml(item.choiceQuestion.content);
                let materialList = item.choiceQuestion.materialList;
                if (materialList && materialList.length > 0) {
                  item.material = mdUtil.richTextUnitToHtml(materialList[0].content);
                  item.material = item.material.substring(0, 140);
                }

                item.source = item.choiceQuestion.source;
                item.extraInfo = item.choiceQuestion.extraInfo;

                let line = "";
                if (item.choiceQuestion.fbKpInfo && item.choiceQuestion.fbKpInfo != "") {
                  let tmp = JSON.parse(item.choiceQuestion.fbKpInfo);
                  let lineTmp = [];
                  if (tmp) {
                    for (let x of tmp) {
                      lineTmp.push(x.map((x) => x.name).join("-"));
                    }
                    line = lineTmp.join("; <br/>");
                  }
                }
                item.fbKpInfo = line;
              } else if (item.type == 1) {
                // blank
                item.title = mdUtil.richTextUnitToHtml(item.blankQuestion.title);
                item.content = "";

                let materialList = item.blankQuestion.materialList;
                if (materialList && materialList.length > 0) {
                  item.material = mdUtil.richTextUnitToHtml(materialList[0].content);
                  item.material = item.material.substring(0, 140);
                }
                item.source = item.blankQuestion.source;
                item.extraInfo = item.blankQuestion.extraInfo;

                let line = "";
                if (item.blankQuestion.fbKpInfo && item.blankQuestion.fbKpInfo != "") {
                  let tmp = JSON.parse(item.blankQuestion.fbKpInfo);
                  let lineTmp = [];
                  if (tmp) {
                    for (let x of tmp) {
                      lineTmp.push(x.map((x) => x.name).join("-"));
                    }
                    line = lineTmp.join("; \n");
                  }
                }
                item.fbKpInfo = line;
              }
            }
            this.tableData = data;
          }
        })
        .catch((err) => {
          msgLogger.error("error:" + err);
        });
    },
    onChangePage(pageNo) {
      this.currentPage = pageNo;
      this.start = (this.currentPage - 1) * this.len;
      this.onSearch();
    },
    onEditQuestion(index, row) {
      if (row.type == 0) {
        let path = `/tiku/aida-tiku-choice-add?subjectCourseId=${row.subjectCourseId}&id=${row.id}`;
        let routeData = this.$router.resolve({ path: path });
        window.open(routeData.href, "_blank");
      } else if (row.type == 1) {
        let path = `/tiku/aida-tiku-blank-add?subjectCourseId=${row.subjectCourseId}&id=${row.id}`;
        let routeData = this.$router.resolve({ path: path });
        window.open(routeData.href, "_blank");
      }
    },
    onViewQuestion(index, row) {
      let path = `/tiku/aida-tiku-question-view?subjectCourseId=${row.subjectCourseId}&id=${row.id}`;
      let routeData = this.$router.resolve({ path: path });
      window.open(routeData.href, "_blank");
    },
    onExport() {
      let path = `/tiku/plain-text-question-view?subjectCourseId=${this.subjectCourseId}&ids=${this.ids}`;
      let routeData = this.$router.resolve({ path: path });
      window.open(routeData.href, "_blank");
    },
  },
  watch: {},
};
</script>

<style lang="less" scoped>
.main {
  display: flex;
  flex-direction: column;
  width: 95%;
  .tool {
    display: flex;
    flex-direction: column;
    .row {
      display: flex;
      flex-direction: row;
      margin: 10px 0;
      .label {
        width: 80px;
        min-height: 40px;
        display: inline-flex;
        align-items: center;
      }
      .input-text {
        width: 400px;
      }
    }
  }
  .table {
    width: 100%;
  }
  .footer {
    margin: 10px 0;
  }
}
</style>
